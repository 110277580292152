<template>
  <Section>
    <Container>
      <div class="title-bar">
        <div class="title-bar-title">
          <h5>Résumé du protocole</h5>
          <h2>{{ essai?.protocole?.titre }}</h2>
        </div>
        <div class="title-bar-action">
          <Btn v-if="helperService.userHasPermission('essaiplan_add')"
          class="margin-bottom"  color="default"  text="Enregistrer le plan dans la bibliothèque de plans" @click="saveModal = true"  />

          <Btn class="margin-bottom"
            text="Imprimer le plan"
            color="default"
            icon="print"
            @click="printPlan('pdf')" />
          <Btn class="margin-bottom"
            text="Télécharger le plan (xls)"
            color="default"
            icon="print"
            @click="printPlan('xls')" />
        </div>
      </div>

      <div class="protocol-counters">
        <div class="protocol-counter">
          <div class="protocol-counter-value">{{ facteur.length }}</div>
          <div class="protocol-counter-label">Facteurs</div>
        </div>
        <div class="protocol-counter">
          <div class="protocol-counter-value">{{ modalite.length }}</div>
          <div class="protocol-counter-label">
            <template v-if="facteur.length === 1">
              Modalités
            </template>
            <template v-if="facteur.length > 1">
              Combinaisons de modalités
            </template>
          </div>
        </div>
      </div>

      <div class="grid grid--compact">
        <div class="tmp-col">
          <KeyValue label="Type de dispositif"
            :value="(essai?.plan?.type?.uid !== 'MANUEL') ? essai?.plan?.type?.designation : essai?.plan?.type_analyse?.designation" />
        </div>
        <div class="tmp-col">
          <KeyValue label="Facteur principal"
            :value="essai?.plan?.facteur?.type?.designation" />
        </div>
        <div class="tmp-col">
          <KeyValue label="Nombre de répétitions"
            :value="essai?.plan?.nb_repetitions" />
        </div>
        <div class="tmp-col">
          <KeyValue label="Orientation générale"
            :value="essai?.plan?.orientation_generale?.designation" />
        </div>
      </div>
    </Container>
  </Section>

  <Section>
    <Container>
      <div class="grid">
        <div class="tmp-col">
          <DisplayPlanOptions v-if="essai?.plan"
          :planId="essai?.plan?.id"
          @display-plan-options='updateDisplayOptions' />
        </div>
        <div class="tmp-col">
          <DisplayPlanModalitiesOptions v-if="essai?.plan"
          :planId="essai?.plan?.id"
          :essai="essai"
          @display-plan-modalities-options='updateDisplayModalitiesOptions' />
        </div>

      </div>
    </Container>
  </Section>

  <Section>
    <Container>
      <MicroParcels v-if="microParcelsData.length > 0"
        :microParcelsData="microParcelsData"
        :isEditable="false"
        :displayBorders="true"
        :borders="bordersVmodel"
        :displayHeaders="displayOptions?.options?.displayHeaders"
        :displayGeo="displayOptions?.options?.displayGeo"
        :displayComputedId="displayOptions?.options?.displayComputedId"
        :displayRepetition="displayOptions?.options?.displayRepetition"
        :displayModalityNumber="displayOptions?.options?.displayModalityNumber"
        :displayDesignation="displayOptions?.options?.displayDesignation"
        :displayColors="displayOptions?.options?.displayColors"
        :hiddenModalities="displayOptions?.modalites_off"
        :startingPoint="essai?.plan?.point_depart?.uid"
        canPanY />
      <template v-else> Le plan n'a pas encore été généré. </template>
    </Container>
  </Section>

  <Modal v-if="plan" title="Enregistrer le plan dans la bibliothèque" :active="saveModal" :data="plan" :containForm="true"
    :modalValidationSchema="modalValidationSchema" @modal-close="saveModal = false"
    @modal-form-submitted="savePlanInLibrary($event)">
    <template v-slot:modal-body>
      <Input id="nom" label="Désignation du plan" />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="saveModal = false" />
      <Btn btnType="submit" text="Enregistrer" color="primary" />
    </template>
  </Modal>

</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import MicroParcels from '@/components/micro-parcels/MicroParcels.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import DisplayPlanOptions from '@/views/componentsViews/plan/DisplayPlanOptions.vue'
import DisplayPlanModalitiesOptions from '@/views/componentsViews/plan/DisplayPlanModalitiesOptions.vue'
import Modal from '@/components/layout/Modal.vue'
import Input from '@/components/form/Input.vue'

export default {
  name: 'TestPlanSubview',
  components: {
    DisplayPlanOptions,
    Section,
    Container,
    MicroParcels,
    KeyValue,
    Btn,
    DisplayPlanModalitiesOptions,
    Modal,
    Input,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },
  data() {
    // Define a validation schema
    const modalValidationSchema = this.yup.object().shape({
      nom: this.yup.string().required(),
    })

    return {
      plan: this.essai.plan,
      modalite: [],
      facteur: [],
      microParcelsData: [],
      bordersVmodel: [],
      displayOptions: {},
      saveModal: false,
      modalValidationSchema,
    }
  },
  emits: ['update:modelValue'],
  created() {
    if (this.essai?.id && this.essai?.plan?.id) {
      this.loadData()
    }
  },
  methods: {
    loadData() {
      this.emitter.emit('open-loader')

      this.fetchService
        .get(`protocole/${this.essai.protocole.id}/modalite`, {
          limit: 0,
        })
        .then((reponseModalite) => {
          this.modalite = reponseModalite.data
        })
      this.fetchService
        .get(`protocole/${this.essai.protocole.id}/facteur`, {
          limit: 0,
        })
        .then((reponseFacteur) => {
          this.facteur = reponseFacteur.data
        })

      this.fetchService
        .get(
          `essai/${this.essai.id}/plan/${this.essai.plan.id}/microparcelle`,
          {
            sort: 'x.ASC,y.ASC',
            limit: 0,
          },
        )
        .then((response) => {
          this.microParcelsData = response.data
          this.emitter.emit('close-loader')
        })

      this.bordersVmodel = this.essai.plan.bordures.map((bordure) => bordure.uid)
    },
    updateDisplayOptions(options) {
      this.displayOptions.options = options
    },
    updateDisplayModalitiesOptions(options) {
      if (options && options.length) {
        this.displayOptions.modalites_off = options.join(',')
      } else {
        this.displayOptions.modalites_off = ''
      }
    },
    printPlan(type) {
      const printOptions = {
        position_geographique: this.displayOptions?.options?.displayGeo ? 1 : 0,
        modalite_bloc: this.displayOptions?.options?.displayComputedId ? 1 : 0,
        numero_ligne: this.displayOptions?.options?.displayHeaders ? 1 : 0,
        numero_colonne: this.displayOptions?.options?.displayHeaders ? 1 : 0,
        numero_modalite: this.displayOptions?.options?.displayModalityNumber ? 1 : 0,
        numero_bloc: this.displayOptions?.options?.displayRepetition ? 1 : 0,
        libelle_modalite: this.displayOptions?.options?.displayDesignation ? 1 : 0,
        couleurs: this.displayOptions?.options?.displayColors ? 1 : 0,
      }

      if ('modalites_off' in this.displayOptions) {
        printOptions.modalites_off = this.displayOptions.modalites_off
      }

      this.fileService.exportFile(type, 'essai', this.essai.id, 'plan', printOptions)
    },
    savePlanInLibrary(data) {
      this.emitter.emit('open-loader')
      const formValues = this.helperService.cloneObject(this.plan)

      if (formValues.type.uid === 'MANUEL') {
        formValues.type_uid = 'MANUEL'
        formValues.type_analyse_uid = formValues.type_analyse.uid
      } else {
        formValues.type_uid = formValues.type.uid
      }

      formValues.nom = data.nom
      formValues.orientation_generale_uid = formValues.orientation_general?.uid
      formValues.point_depart_uid = formValues.point_depart?.uid
      formValues.marge_uid = formValues.position_temoins?.uid
      formValues.nb_modalites = this.modalite.length
      formValues.disposition = formValues.id
      formValues.bordures = formValues.bordures.map((bordure) => ({
        uid: bordure.uid,
      }))
      delete formValues.id

      this.fetchService
        .post('essai/plan', formValues)
        .then(() => {
          this.emitter.emit('close-loader')
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le plan a bien été enregistré dans la bibliothèque.',
          })
        })
        .finally(() => {
          this.saveModal = false
          this.emitter.emit('close-loader')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.title-bar {
  @include bp('xs') {
    display: flex;
    flex-wrap: wrap;
  }
}

.title-bar-title {
  flex-grow: 1;
}

.title-bar-action {
  button+button{
    margin-left: $gutter-quarter ;
  }
}
</style>
